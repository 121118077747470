import React, { createContext, useState } from 'react'

export const ClassContext = createContext()

const ClassContextProvider = (props) => {
  const [AVState, setAVState] = useState({
    video: false,
    audio: false,
  })

  // teacher's control for disabling chat for entire class
  const [disableChatForClass, setDisableChatForClass] = useState(false)

  // student's knowledge for chat
  const [isChatEnabled, setIsChatEnabled] = useState(true)

  const [enableBlur, setEnableBlur] = useState(false)
  const [enableMinimize, setEnableMinimize] = useState(false)
  const [hideMinimize, setHideMinimize] = useState(false)
  const [isPinned, setIsPinned] = useState(false)
  const [isScreenSharedGlobal, setIsScreenSharedGlobal] = useState(false)

  const [giveAVPermissionToStudent, setGiveAVPermissionToStudent] =
    useState(true)

  return (
    <ClassContext.Provider
      value={{
        AVState,
        setAVState,
        disableChatForClass,
        setDisableChatForClass,
        isChatEnabled,
        setIsChatEnabled,
        giveAVPermissionToStudent,
        setGiveAVPermissionToStudent,
        enableBlur,
        setEnableBlur,
        enableMinimize,
        setEnableMinimize,
        hideMinimize,
        setHideMinimize,
        isPinned,
        setIsPinned,
        isScreenSharedGlobal,
        setIsScreenSharedGlobal,
      }}
    >
      {props.children}
    </ClassContext.Provider>
  )
}
export default ClassContextProvider
