import React from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import { SnackbarProvider } from 'notistack'
import { ThemeProvider } from '@material-ui/styles'
import App from './App'
import reportWebVitals from './reportWebVitals'
import MaterialUITheme from './MaterialUITheme'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
/* eslint-disable-next-line */
import { firebase, signInFirebase } from './firebase/firebase'

ReactDOM.render(
  <React.StrictMode>
    <SnackbarProvider maxSnack={4}>
      <ThemeProvider theme={MaterialUITheme}>
        <App />
      </ThemeProvider>
    </SnackbarProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)

serviceWorkerRegistration.register()
signInFirebase()
reportWebVitals()
