import React, { useContext, useEffect, useState } from 'react'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import Avatar from '@mui/material/Avatar'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import { getValue, ensureInitialized } from 'firebase/remote-config'
import ModalVideo from 'react-modal-video'
import LaunchIcon from '@mui/icons-material/Launch'
import SendIcon from '@mui/icons-material/Send'
import Button from '@mui/material/Button'
import recordIcon from '../../Assets/Images/HelpSupportShowcaseBg.png'
import styles from '../../Styles/HelpAndSupport.module.css'
import { analytics, remoteConfig } from '../../firebase/firebase'
import { AuthContext } from '../../Context/AuthContext'
import { RemoteConfigKeys } from '../../firebase/remoteConfig'
import 'react-modal-video/scss/modal-video.scss'
import WhatsappIcon from '../../Assets/Images/whatsapp.svg'

const HelpAndSupport = () => {
  const { authState } = useContext(AuthContext)
  const [tutorFaqs, setTutorFaqs] = useState([])
  const [studentFaqs, setStudentFaqs] = useState([])
  const [launchPlayer, setLaunchPayer] = useState({ video: '', launch: false })
  const policiesBaseUrl = 'https://policies.edvi.app'

  useEffect(() => {
    analytics.setUserProperties({
      name: authState.name,
      email: authState.email,
      role: authState.role,
      phone_number: authState.phone_number,
    })
  }, [])

  const fetchRemoteValues = () => {
    ensureInitialized(remoteConfig).then(() => {
      const remoteConfigValue = getValue(
        remoteConfig,
        RemoteConfigKeys.FAQS,
      ).asString()
      const configFaqs = JSON.parse(
        !remoteConfigValue ? '{}' : remoteConfigValue,
      )
      const tutorFaqsConfig =
        'tutor_faqs' in configFaqs ? configFaqs.tutor_faqs : []
      const studentFaqsConfig =
        'student_faqs' in configFaqs ? configFaqs.student_faqs : []
      if (tutorFaqsConfig !== null && tutorFaqsConfig.length !== 0) {
        setTutorFaqs(tutorFaqsConfig)
      }
      if (studentFaqsConfig !== null && studentFaqsConfig.length !== 0) {
        setStudentFaqs(studentFaqsConfig)
      }
    }, null)
  }

  useEffect(() => {
    fetchRemoteValues()
  }, [authState.role])

  return (
    <div>
      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={launchPlayer.launch}
        videoId={launchPlayer.video}
        onClose={() => setLaunchPayer({ video: '', launch: false })}
      />
      <div className={styles.showcase__container}>
        {/* ---HEADER---*/}
        <div className={styles.showcase__bgImg}>
          <img src={recordIcon} alt="edvi logo" height={500} width={500} />
        </div>

        <div className={styles.showcase__content}>
          <h1 className={styles.showcase__header}>Help and Support</h1>
          <p className={styles.showcase__para}>
            Hi, How can we help you today?
          </p>
        </div>
      </div>
      <div className={styles.content__container}>
        <div className={styles.content_container_faq}>
          <p className={styles.showcase__para}>Frequently Asked Questions</p>
          {(authState === null && authState.role === null) ||
            (authState.role === '' && (
              <p className={styles.footer__link__header}>Tutor Faqs</p>
            ))}
          {(authState.role === 'T' ||
            authState === null ||
            authState.role === null ||
            authState.role === '') && (
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
              {tutorFaqs.map((value) => {
                const labelId = `checkbox-list-secondary-label-${value.id}`
                return (
                  <ListItem
                    key={value.id}
                    secondaryAction={<ArrowForwardIosIcon />}
                    disablePadding
                    onClick={() => {
                      setLaunchPayer({
                        video: `${new URLSearchParams(
                          new URL(value.video_url).search,
                        ).get('v')}`,
                        launch: true,
                      })
                    }}
                  >
                    <ListItemButton>
                      <ListItemAvatar>
                        <Avatar
                          alt={`Avatar n°${value.id}`}
                          src={`https://img.youtube.com/vi/${new URLSearchParams(
                            new URL(value.video_url).search,
                          ).get('v')}/default.jpg`}
                          variant="rounded"
                        />
                      </ListItemAvatar>
                      <ListItemText id={labelId} primary={value.action_title} />
                    </ListItemButton>
                  </ListItem>
                )
              })}
            </List>
          )}
          {(authState === null && authState.role === null) ||
            (authState.role === '' && (
              <p className={styles.footer__link__header}>Student Faqs</p>
            ))}
          {(authState.role === 'S' ||
            authState === null ||
            authState.role === null ||
            authState.role === '') && (
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
              {studentFaqs.map((value) => {
                const labelId = `checkbox-list-secondary-label-${value.id}`
                return (
                  <ListItem
                    key={value.id}
                    secondaryAction={<ArrowForwardIosIcon />}
                    disablePadding
                    onClick={() => {
                      setLaunchPayer({
                        video: `${new URLSearchParams(
                          new URL(value.video_url).search,
                        ).get('v')}`,
                        launch: true,
                      })
                    }}
                  >
                    <ListItemButton>
                      <ListItemAvatar>
                        <Avatar
                          alt={`Avatar n°${value.id}`}
                          src={`https://img.youtube.com/vi/${new URLSearchParams(
                            new URL(value.video_url).search,
                          ).get('v')}/default.jpg`}
                          variant="rounded"
                        />
                      </ListItemAvatar>
                      <ListItemText id={labelId} primary={value.action_title} />
                    </ListItemButton>
                  </ListItem>
                )
              })}
            </List>
          )}
        </div>
        <div className={styles.separator}></div>
        <div className={styles.content_container_other}>
          <p className={styles.showcase__para}>Still having query?</p>
          <div className={styles.other_header_container}>
            <Button
              variant="outlined"
              endIcon={<SendIcon />}
              onClick={() => {
                window.$chatwoot.toggle()
              }}
            >
              Connect now
            </Button>
          </div>
          <div style={{ boxSizing: 'content-box' }} className={styles.para}>
            <span>Call or WhatsApp </span>
            <img
              src={WhatsappIcon}
              alt="Whatsapp"
              style={{ position: 'relative', top: '3px' }}
            />
            <a style={{ color: '#666' }} href="tel:+919569768932">
              {' '}
              at
              <span style={{ color: '#3458a1' }}> +919569768932</span>
              <LaunchIcon
                sx={{
                  position: 'relative',
                  top: '5px',
                  fontSize: '20px',
                  color: '#3458a1',
                }}
              />
            </a>
          </div>
          <div>
            {/* quick links */}
            <p className={styles.footer__link__header}>Policies</p>
          </div>
          {(authState.role === 'S' ||
            authState === null ||
            authState.role === null ||
            authState.role === '') && (
            <a
              href={`${policiesBaseUrl}/privacy`}
              target="_blank"
              rel="noreferrer"
              className={styles.footer__link}
            >
              Privacy Policy for Parents
            </a>
          )}
          {(authState.role === 'S' ||
            authState === null ||
            authState.role === null ||
            authState.role === '') && (
            <a
              href={`${policiesBaseUrl}/terms`}
              target="_blank"
              rel="noreferrer"
              className={styles.footer__link}
            >
              Terms and Conditions for Parents
            </a>
          )}
          {(authState.role === 'T' ||
            authState === null ||
            authState.role === null ||
            authState.role === '') && (
            <a
              href={`${policiesBaseUrl}/tutor/terms`}
              target="_blank"
              rel="noreferrer"
              className={styles.footer__link}
            >
              Privacy Policy for Tutors
            </a>
          )}
          {(authState.role === 'T' ||
            authState === null ||
            authState.role === null ||
            authState.role === '') && (
            <a
              href={`${policiesBaseUrl}/tutor/terms`}
              target="_blank"
              rel="noreferrer"
              className={styles.footer__link}
            >
              Terms and Conditions for Tutors
            </a>
          )}
          <a
            href={`${policiesBaseUrl}/terms#refund`}
            target="_blank"
            rel="noreferrer"
            className={styles.footer__link}
          >
            Cancellation and Refunds
          </a>
          <div>
            <p className={styles.footer__link__header}>Address</p>
          </div>
          <div>
            <p className={styles.footer__address}>CRESCENDO COWORK</p>
            <p className={styles.footer__address}>
              Ground Floor, MCT House, New Friends Colony, New Delhi, 110025.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HelpAndSupport
