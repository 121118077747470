import { useSnackbar } from 'notistack'
import { React, useContext, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import Spinner from '../../Components/Progress/Spinner'
import showErrorSnackbar from '../../Components/Snackbar/errorSnackbar'
import { AuthContext } from '../../Context/AuthContext'
import { BatchContext } from '../../Context/BatchContext'

function BatchLinks() {
  const history = useHistory()
  const { batchId, type, action, classId } = useParams()
  const { authState } = useContext(AuthContext)
  const {
    FindBatchWithCode,
    ResumeBatchLecture,
    StartBatchLecture,
    getAllReqAndBatches,
  } = useContext(BatchContext)
  const { enqueueSnackbar } = useSnackbar()

  const getBatchId = () => batchId.toLowerCase()

  const redirect = (path = '/dashboard', state) => {
    if (state) {
      history.push(path, state)
    } else {
      history.push(path)
    }
  }

  const joinLecture = async (lectureId) => {
    try {
      const res = await ResumeBatchLecture(lectureId)
      if (!res) {
        showErrorSnackbar(enqueueSnackbar, 'Cannot join the lecture!')
        redirect()
      }
      const {
        app_id,
        rtc_token,
        rtm_token,
        started_at,
        screen_share_token,
        secret,
        salt,
      } = res.data
      redirect(`/dashboard/class/${lectureId}`, {
        startClass: true,
        appId: app_id,
        RTCToken: rtc_token,
        RTMToken: rtm_token,
        classStartTime: started_at,
        role: 'T',
        batchId: getBatchId(),
        screenShareToken: screen_share_token,
        secret,
        salt,
      })
    } catch (err) {
      redirect()
    }
  }

  const startClass = async () => {
    try {
      const res = await StartBatchLecture(getBatchId())
      if (!res) {
        showErrorSnackbar(enqueueSnackbar, "Couldn't start the lecture")
        redirect()
      }
      const {
        app_id,
        rtc_token,
        rtm_token,
        started_at,
        lecture_id,
        screen_share_token,
        secret,
        salt,
      } = res
      redirect(`/dashboard/class/${lecture_id}`, {
        startClass: true,
        appId: app_id,
        RTCToken: rtc_token,
        RTMToken: rtm_token,
        classStartTime: started_at,
        role: 'T',
        batchId: getBatchId(),
        screenShareToken: screen_share_token,
        secret,
        salt,
      })
    } catch (err) {
      redirect()
    }
  }

  const studentBatchFound = async () => {
    const data = await FindBatchWithCode(getBatchId())
    if (data && data.participant === 'Y') {
      return { isJoined: true, requests: [] }
    }
    if (data && data.participant === 'N') {
      const { request } = await getAllReqAndBatches()
      return { isJoined: false, requests: request }
    }
    return { isJoined: false, requests: [] }
  }
  const cta = async () => {
    switch (type) {
      case undefined: {
        if (batchId && !action) {
          if (authState.role === 'T') {
            const data = await FindBatchWithCode(getBatchId())
            if (!data) {
              showErrorSnackbar(enqueueSnackbar, 'This batch does not exist')
              redirect()
            } else redirect(`/dashboard/view/${getBatchId()}`)
          } else if (authState.role === 'S') {
            const { isJoined, requests } = await studentBatchFound()
            if (isJoined) {
              redirect(`/dashboard/view/${getBatchId()}`)
            } else if (requests.find((req) => req.batch_id === getBatchId())) {
              const request = requests.find(
                (req) => req.batch_id === getBatchId(),
              )
              showErrorSnackbar(
                enqueueSnackbar,
                `Wait for ${request.teacher} to accept your request`,
              )
              redirect()
            } else {
              showErrorSnackbar(
                enqueueSnackbar,
                `Join batch ${getBatchId()} first`,
              )
              redirect(`/dashboard`, { requestBatch: getBatchId() })
            }
          }
        }
        break
      }
      case 'search': {
        if (authState.role === 'T') redirect()
        else if (authState.role === 'S') {
          redirect(`/dashboard`, { requestBatch: getBatchId() })
        }
        break
      }
      case 'students': {
        if (action !== undefined || authState.role === 'S') redirect()
        const data = await FindBatchWithCode(getBatchId())
        if (data === undefined) {
          showErrorSnackbar(enqueueSnackbar, 'This batch does not exist!')
          redirect()
        } else if (data === false) {
          showErrorSnackbar(enqueueSnackbar, 'Something went wrong!')
          redirect()
        } else {
          redirect(`/dashboard/students/${getBatchId()}`)
        }
        break
      }
      case 'schedule': {
        if (action !== undefined || authState.role === 'S') redirect()
        const data = await FindBatchWithCode(getBatchId())
        if (data === undefined) {
          showErrorSnackbar(enqueueSnackbar, 'This batch does not exist!')
          redirect()
        } else if (data === false) {
          showErrorSnackbar(enqueueSnackbar, 'Something went wrong!')
          redirect()
        } else {
          redirect(`/dashboard/timings/${getBatchId()}`)
        }
        break
      }
      case 'lecture': {
        if (action !== 'join') redirect()
        if (authState.role === 'T') {
          const data = await FindBatchWithCode(getBatchId())
          if (!data || !data) redirect()
          if (data.lecture) {
            joinLecture(data.lecture.id)
          } else {
            startClass()
          }
        } else if (authState.role === 'S') {
          const { isJoined, requests } = await studentBatchFound()
          if (isJoined) {
            const data = await FindBatchWithCode(getBatchId())
            if (!data) {
              redirect()
            }
            if (data.lecture) {
              joinLecture(data.lecture.id)
            } else if (
              data.next_lecture_timing &&
              Math.trunc(new Date().getTime() / 1000) >=
                data.next_lecture_timing.starts &&
              Math.trunc(new Date().getTime() / 1000) <
                data.next_lecture_timing.ends
            ) {
              startClass()
            }
          } else if (requests.find((req) => req.batch_id === getBatchId())) {
            const request = requests.find(
              (req) => req.batch_id === getBatchId(),
            )
            if (request.status === 'R') {
              showErrorSnackbar(
                enqueueSnackbar,
                `${request.teacher} has declined your join request`,
              )
              redirect()
            } else if (request.status === 'D') {
              showErrorSnackbar(
                enqueueSnackbar,
                `Wait for ${request.teacher} to accept your join request`,
              )
              redirect()
            }
          } else {
            showErrorSnackbar(
              enqueueSnackbar,
              `Join batch ${getBatchId()} first`,
            )
            redirect(`/dashboard`, { requestBatch: getBatchId() })
          }
        }
        break
      }
      case 'attendance':
      case 'notes':
      case 'assignments':
      case 'notice':
      case 'user-attendance': {
        if (authState.role === 'S') {
          const data = await studentBatchFound()
          const { isJoined, requests } = data
          if (isJoined) {
            redirect(`/dashboard/attendance/${getBatchId()}/${classId}`)
          } else if (requests.find((req) => req.batch_id === getBatchId())) {
            const request = requests.find(
              (req) => req.batch_id === getBatchId(),
            )
            showErrorSnackbar(
              enqueueSnackbar,
              `Wait for ${request.teacher} to accept your request`,
            )
            redirect()
          } else {
            showErrorSnackbar(
              enqueueSnackbar,
              `Join batch ${getBatchId()} first`,
            )
            redirect(`/dashboard`, { requestBatch: getBatchId() })
          }
        }
        if (authState.role === 'T') {
          if (action === 'details') {
            const data = await FindBatchWithCode(getBatchId())
            if (!data) {
              showErrorSnackbar(enqueueSnackbar, 'This batch does not exist')
              redirect()
            } else {
              redirect(`/dashboard/attendance/${getBatchId()}/${classId}`)
            }
            return
          }
          if (action === 'add') {
            const data = await FindBatchWithCode(getBatchId())
            if (!data) {
              showErrorSnackbar(enqueueSnackbar, 'This batch does not exist')
              redirect()
            } else {
              redirect(`/dashboard/attendance/${getBatchId()}/add`)
            }
            return
          }
          if (action === 'history') {
            const data = await FindBatchWithCode(getBatchId())
            if (!data) {
              showErrorSnackbar(enqueueSnackbar, 'This batch does not exist')
              redirect()
            } else {
              redirect(
                `/dashboard/billedClassesDetails/${getBatchId()}/${classId}`,
              )
            }
            return
          }
          redirect('/')
        }
        break
      }
      case 'recordings': {
        if (action) redirect()
        if (authState.role === 'T') {
          const data = await FindBatchWithCode(getBatchId())
          if (!data) {
            showErrorSnackbar(enqueueSnackbar, 'This batch does not exist')
            redirect()
          } else if (type === 'attendance')
            redirect(`/dashboard/attendance/${getBatchId()}`)
          else if (type === 'notes')
            redirect(`/dashboard/notes/${getBatchId()}`)
          else if (type === 'assignments')
            redirect(`/dashboard/assignment/${getBatchId()}`)
          else if (type === 'recordings')
            redirect(`/dashboard/recordings/${getBatchId()}`)
          else if (type === 'notice')
            redirect(`/dashboard/notice/${getBatchId()}`)
        } else if (authState.role === 'S') {
          const { isJoined, requests } = await studentBatchFound()
          if (isJoined) {
            if (type === 'attendance')
              redirect(`/dashboard/attendance/${getBatchId()}`)
            else if (type === 'notes')
              redirect(`/dashboard/notes/${getBatchId()}`)
            else if (type === 'assignments')
              redirect(`/dashboard/assignment/${getBatchId()}`)
            else if (type === 'recordings')
              redirect(`/dashboard/recordings/${getBatchId()}`)
            else if (type === 'notice')
              redirect(`/dashboard/view/${getBatchId()}`)
          } else if (requests.find((req) => req.batch_id === getBatchId())) {
            const request = request.find((req) => req.batch_id === getBatchId())
            showErrorSnackbar(
              enqueueSnackbar,
              `Wait for ${request.teacher} to accept your request`,
            )
            redirect()
          } else {
            showErrorSnackbar(
              enqueueSnackbar,
              `Join batch ${getBatchId()} first`,
            )
            redirect(`/dashboard`, { requestBatch: getBatchId() })
          }
        }
        break
      }
      case 'batch-test': {
        redirect(`/dashboard/examDetails/${getBatchId()}/${classId}`)
        break
      }
      default:
        redirect()
        break
    }
  }

  useEffect(() => {
    if (!batchId) {
      redirect()
    } else {
      cta()
    }
  }, [])

  return <Spinner />
}

export default BatchLinks
