import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Spinner from '../../Components/Progress/Spinner'

function AuthLinks() {
  const history = useHistory()
  const { type } = useParams()

  const redirect = (path = '/login', state) => {
    if (state) {
      history.push(path, state)
    } else {
      history.push(path)
    }
  }

  const cta = () => {
    switch (type) {
      case 'login':
        redirect('/auth/login')
        break
      case 'register':
        redirect('/auth/register')
        break
      case 'reset_password':
        redirect('/dashboard', { changePassword: true })
        break
      default:
        redirect('/dashboard')
        break
    }
  }

  useEffect(() => {
    if (!type) redirect()
    else cta()
  }, [])

  return <Spinner />
}

export default AuthLinks
