export const RemoteConfigKeys = {
  SHOW_RATING: 'SHOW_RATING',
  ALLOW_AV_CONTROLS: 'ALLOW_AV_CONTROLS',
  PRIVACY_POLICY_URL: 'PRIVACY_POLICY_URL',
  SUPPORT_URL: 'SUPPORT_URL',
  TNC_URL: 'TNC_URL',
  ALLOW_STUDENT_SCREEN_SHARE: 'ALLOW_STUDENT_SCREEN_SHARE',
  ALLOW_EMAIL_SIGNUP: 'ALLOW_EMAIL_SIGNUP',
  ALLOW_PHONE_SIGNUP: 'ALLOW_PHONE_SIGNUP',
  HELP_TICKETS: 'TICKET_LIST',
  CLASS_LIST: 'STD_LIST',
  BOARD_LIST: 'BOARD_LIST',
  GENERATE_SHORT_LINKS: 'GENERATE_SHORT_LINKS',
  DEEPLINK_IMAGE: 'DEEPLINK_IMAGE',
  PHONE_OTP_LENGTH: 'PHONE_OTP_LENGTH',
  ENABLE_FRESHCHAT: 'ENABLE_FRESHCHAT',
  SHOW_MARKETPLACE: 'SHOW_MARKETPLACE',
  EXTEND_CLASS_MINS: 'EXTEND_CLASS_MINS',
  CHAT_MAX_FILE_SIZE: 'CHAT_MAX_FILE_SIZE',
  MKT_STANDARD_LIST: 'STANDARD_LIST',
  MKT_SUBJECT_LIST: 'SUBJECT_LIST',
  MKT_BOARDS_LIST: 'MKT_BOARD_LIST',
  BANK_LIST: 'BANK_LIST',
  MKT_REVIEW_OPTIONS: 'MKT_FEEDBACK_LIST',
  ENABLE_BATCH_CHAT: 'ENABLE_BATCH_CHAT',
  DEFAULT_FEE: 'DEFAULT_FEE',
  COUNTRY_PHONES: 'COUNTRY_PHONES',
  CALL_BLUR_INTENSITY: 'CALL_BLUR_INTENSITY',
  DELETE_ACCOUNT_DESC: 'DELETE_ACCOUNT_DESC',
  SUPPORT_NUMBER: 'SUPPORT_NUMBER',
  BANK_ACCOUNT_DETAILS: 'BANK_ACCOUNT_DETAILS',
  ALLOW_REGISTRATION: 'ALLOW_REGISTRATION',
  STUDENT_NO_SHOW_DESC: 'STUDENT_NO_SHOW_DESC',
  TEACHER_NO_SHOW_DESC: 'TEACHER_NO_SHOW_DESC',
  FAQS: 'FAQS',
  TEACHER_MARKET_PLACE_URL: 'TEACHER_MARKET_PLACE_URL',
  STUDENT_MARKET_PLACE_URL: 'STUDENT_MARKET_PLACE_URL',
  BILLABLE_HOURS: 'ATTENDANCE_BILLABLE_HOURS',
  MIN_CLASS_DURATION: 'MIN_CLASS_DURATION',
  PAYMENT_REQUEST_DATE_RANGE: 'PAYMENT_REQUEST_DATE_RANGE',
  FEATURE_PERMISSIONS: 'FEATURE_PERMISSIONS',
  PARENT_LOGIN_COUNTRIES: 'PARENT_LOGIN_COUNTRIES',
}

export const RemoteConfigDefaults = {
  PARENT_LOGIN_COUNTRIES: '["IN","SG","AU","QA","AE","US","GB"]',
}
