import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Spinner from '../../Components/Progress/Spinner'

function ProfileLinks() {
  const history = useHistory()
  const { type } = useParams()

  const redirect = (path = '/dashboard/editprofile', state) => {
    if (state) {
      history.push(path, state)
    } else {
      history.push(path)
    }
  }

  const cta = () => {
    switch (type) {
      case 'edit':
        redirect()
        break
      default:
        redirect('/dashboard')
        break
    }
  }

  useEffect(() => {
    if (!type) redirect()
    else cta()
  }, [])

  return <Spinner />
}

export default ProfileLinks
