import firebase from 'firebase/compat/app'
import 'firebase/compat/analytics'
import { getFirestore } from 'firebase/firestore'
import { getAuth, signInAnonymously } from 'firebase/auth'
import { getRemoteConfig, fetchAndActivate } from 'firebase/remote-config'

// Edvi main
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FB_API_KEY,
  authDomain: 'edvi-main.firebaseapp.com',
  projectId: 'edvi-main',
  storageBucket: 'edvi-main.appspot.com',
  messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FB_APP_ID,
  measurementId: process.env.REACT_APP_FB_MEASUREMENT_ID,
}

const app = firebase.initializeApp(firebaseConfig)

const auth = getAuth()

const signInFirebase = async () => {
  await signInAnonymously(auth)
  await fetchAndActivate(remoteConfig).catch((error) => {
    console.error(error)
  })
}

const remoteConfig = getRemoteConfig(app)
const analytics = firebase.analytics()
const db = getFirestore(app)
export { firebase, analytics, db, signInFirebase, remoteConfig }
