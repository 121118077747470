export const paymentErrors = {
  batch_400_33:
    'Attendance(s) provided are either not approved, already billed or not in this batch.',
  batch_400_34:
    'Student no show attendance(s) provided are either not approved, already billed or not in this batch.',
  batch_400_37:
    'Teacher no show attendance(s) provided are either not approved, already billed or not in this batch.',
  batch_400_38: 'Payment request has already been processed.',
  batch_400_39: 'Request data is invalid. Please contact admin.',
  batch_400_40: 'A payment request already raised for this batch.',
  batch_400_41:
    'Kindly select at least one approved regular attendance to request payment.',
  batch_404_5: 'Payment request not found for this batch.',
  batch_400_42: 'Attendance(s) in the request are not billed.',
  batch_400_43: 'Student no show attendance(s) in the request are not billed.',
  batch_400_44: 'Teacher no show attendance(s) in the request are not billed',
  batch_400_45:
    'Number of attendance(s) that can be billed does not match the input.',
  batch_400_46:
    'Number of student no show attendance(s) that can be billed does not match the input.',
  batch_400_47:
    'Number of teacher no show attendance(s) that can be billed does not match the input.',
  batch_400_26:
    'Kindly select at least one approved regular attendance to request payment.',
}
