import showErrorSnackbar from '../../Components/Snackbar/errorSnackbar'
import { ErrorCodes } from './errorCodes'

const extractStrings = (data) => {
  const strings = []

  const recursiveExtract = (obj) => {
    Object.values(obj).forEach((value) => {
      if (typeof value === 'string') {
        strings.push(value)
      } else if (typeof value === 'object') {
        recursiveExtract(value)
      }
    })
  }

  recursiveExtract(data)
  return strings
}

export default function handleError(enqueueSnackbar, err) {
  if (err?.response?.data?.code) {
    const { code, detail } = err.response.data
    if (code.toString().charAt(0) === '5') {
      window.location.replace(
        `${window.location.protocol}//${window.location.hostname}${
          window.location.port ? `:${window.location.port}` : ''
        }/internal-error`,
      )
    } else if (ErrorCodes[code]) {
      showErrorSnackbar(enqueueSnackbar, ErrorCodes[code].detail)
    } else {
      showErrorSnackbar(enqueueSnackbar, detail)
    }
  }
  // backward compatibility starts
  else if (err?.response?.status === 401) {
    if (
      err?.response?.data?.detail !== 'Given token not valid for any token type'
    )
      showErrorSnackbar(enqueueSnackbar, err.response.data.detail)
  } else if (err?.response?.status === 400) {
    const values = extractStrings(err.response.data)
    values.forEach((value) => {
      showErrorSnackbar(enqueueSnackbar, value)
    })
  } else if (err?.response?.status === 404) {
    showErrorSnackbar(enqueueSnackbar, err.response.data.detail)
    // check if api is batch detail api, redirect!
    if (err?.response?.config?.url.match(/^\/batch\/([a-zA-Z0-9_-]+)\/?$/)) {
      window.location.replace(
        `${window.location.protocol}//${window.location.hostname}${
          window.location.port ? `:${window.location.port}` : ''
        }`,
      )
    }
  } else if (err?.response?.status.toString().charAt(0) === '5') {
    window.location.replace(
      `${window.location.protocol}//${window.location.hostname}${
        window.location.port ? `:${window.location.port}` : ''
      }/internal-error`,
    )
  }
  // backward compatibility ends
  else if (!err?.response) {
    showErrorSnackbar(enqueueSnackbar, 'Your request has timed out')
  } else {
    showErrorSnackbar(
      enqueueSnackbar,
      'Something went wrong, please try contacting support!',
    )
  }
}
